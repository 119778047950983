<template>
    <div class="container-fluid"
         style="margin-top: 6rem;">
        <section class="d-flex align-items-left justify-content-center flex-column">
            <div>
                <h1 class="blue-text">Defensor del cliente</h1>
            </div>
            <div class="banner-desc">
                <h2 class="blue-text">Reclamaciones Ibancar</h2>
                <p class="blue-text">
                    En Ibancar, tu satisfacción y confianza son nuestra prioridad. Por ello, hemos creado un mostrador
                    virtual dedicado exclusivamente a la defensa de tus derechos como cliente. Nuestro Defensor del
                    Cliente se encargará personalmente de tramitar tu reclamación, analizando detalladamente tu
                    situación y si se han cumplido con todos tus derechos.
                </p>
            </div>
        </section>
        <div class="fade-orange"></div>
    </div>
    <div class="blue-rectangle"></div>

    <div class="container-fluid"
         style="margin-top:2rem">
        <section class="d-flex align-items-left justify-content-center flex-column">
            <div class="d-flex align-items-center justify-content-center document-image">
                <img src="@/assets/img/Document.svg"
                     alt="Document image">
            </div>
            <div class="mt-3">
                <p class="blue-text"
                   style="font-weight: 600;">Por favor rellene el siguiente formulario indicando su reclamación. El
                    defensor del cliente gestionará personalmente su reclamación junto con el Departamento Jurídico y
                    los departamentos dedicados. Recibirá la respuesta a su email en un plazo máximo de 10 días hábiles
                </p>
            </div>
        </section>
        <section></section>
    </div>

    <div class="container-fluid">
        <section class="form-container d-flex flex-column">
            <div>
                <p class="blue-text form-title"
                   style="font-weight: 600;">Contacta con nosotros</p>
                <span class="blue-text form-subtitle">Envíanos un mensaje explicando el motivo de la queja</span>
            </div>
            <form @submit.prevent="handleSubmit" v-if="isFormSend.correct == false && isFormSend.loading == null">
                <div class="form-separator">
                    <label for="nombre-apellido">
                        Nombre y apellidos*
                        <input type="text"
                               id="nombre-apellido"
                               v-model="formData.fullName"
                               required>
                    </label>
                    <label for="motivo-queja">
                        Motivo de la queja*
                        <select name="motivo de queja"
                                id="motivo-queja"
                                placeholder="Selecciona una opción"
                                v-model="formData.motive"
                                required>
                            <option value="Acuerdo de pago">Acuerdo de pago</option>
                            <option value="Incidencias">Incidencias / Quejas / Reclamaciones</option>
                            <option value="Informacion">Información en general</option>
                            <option value="contacto con el departamento legal">Contacto con el departamento legal
                            </option>
                            <option value="Otro">Otro motivo</option>
                        </select>
                    </label>
                    <label for="email">
                        Email*
                        <input type="email"
                               id="email"
                               required
                               v-model="formData.email">
                    </label>
                    <label for="telefono">
                        Teléfono*
                        <input type="tel"
                               id="telefono"
                               required
                               v-model="formData.phone">
                    </label>
                </div>
                <label for="mensaje"
                       id="mensaje">
                    Mensaje
                    <textarea id="mensaje"
                              v-model="formData.message">
                    </textarea>
                </label>

                <div class="submit-container">
                    <button type="submit">
                        Enviar Mensaje
                    </button>
                </div>
            </form>

            <div v-else>
                <div class="form-send-container">
                    <span v-if="isFormSend.loading == true" class="blue-text">Enviando...</span>
                    <span v-if="isFormSend.correct"
                          class="blue-text">¡Formulario enviado correctamente!</span>
                    <span v-if="isFormSend.loading == false && isFormSend.correct == false" style="color: crimson;">Ocurrio un error en el envio, recargue e intente de nuevo.</span>
                </div>
            </div>

            <section class="form-information-container">
                <p style="color: #004680; font-weight: 600;">INFORMACION BASICA SOBRE PROTECCION DE DATOS PERSONALES</p>
                <p>Los datos personales que nos proporciones completando el presente formulario serán tratados por
                    Ibancar World S.L. con la finalidad
                    de gestionar tu solicitud y en base tu consentimiento. </p>
                <p>Puedes ejercer tus derechos de acceso, rectificación, supresión o limitación, oposición y
                    portabilidad enviando un mail a
                    <span class="blue-text">legal@ibancar.com</span>, así como presentar una reclamación ante la
                    Autoridad de Control.
                </p>
                <p>Puedes consultar la información adicional sobre el tratamiento de tus datos personales en cualquier
                    momento accediendo a la <a href="https://ibancar.com/politica-privacidad"
                       class="blue-text">Política de Privacidad</a></p>
            </section>
        </section>
    </div>

    <div class="container-fluid p-5 main-container">
        <div class="container-fluid">
            <div>
                <h2 class="blue-text title text-center">Colaboramos con las mejores marcas del sector</h2>

            </div>
        </div>
    </div>

    <div id="desktop">
        <div id="socios"
             class="d-flex flex-row justify-content-center text-wrap m-4">
            <a href="https://www.iuvo-group.com/es/originator-ibancar/"
               rel="nofollow noopener"
               target="_blank"><img width="150"
                     height="50"
                     class="img-fluid mr-5"
                     src="@/assets/img/logo-iuvo.webp"
                     alt="iuvo"></a>
            <a href="http://www.asnef.com/"
               rel="nofollow noopener"
               target="_blank"><img width="150"
                     height="50"
                     class="img-fluid mr-5"
                     src="@/assets/img/logo-asnef.webp"
                     alt="asnef"></a>
            <a href="https://www.ganvam.es/"
               rel="nofollow noopener"
               target="_blank"><img width="150"
                     height="50"
                     class="img-fluid mr-5"
                     src="@/assets/img/logo-ganvam.webp"
                     alt="ganvam"></a>
            <a href="https://www.bdo.es/es-es/home"
               rel="nofollow noopener"
               target="_blank"><img width="150"
                     height="50"
                     class="img-fluid mr-5"
                     src="@/assets/img/BDO_logo-198_47.webp"
                     alt="bdo"></a>
            <a href="https://www.netsuite.com/"
               rel="nofollow noopener"
               target="_blank"><img width="150"
                     height="50"
                     class="img-fluid mr-5"
                     src="@/assets/img/oracle-netsuite-logo-198_47.webp"
                     alt="oracle"></a>
            <a href="https://kineox.com/"
               rel="nofollow noopener"
               target="_blank"><img width="150"
                     height="50"
                     class="img-fluid mr-5"
                     src="@/assets/img/logo-kineox.webp"
                     alt="oracle"></a>
        </div>
    </div>

    <div id="carousel">
        <div id="mobile"
             class="carousel slide text-center"
             data-touch="true"
             data-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <div class="card p-2">
                        <img 
                             height="50"
                             src="@/assets/img/logo-iuvo.webp"
                             class="card-img-top"
                             alt="ibancar_iuvo">
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="card p-2">
                        <img 
                             height="50"
                             src="@/assets/img/logo-midas.webp"
                             class="card-img-top"
                             alt="ibancar_midas">
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="card p-2">
                        <img 
                             height="50"
                             src="@/assets/img/logo-asnef.webp"
                             class="card-img-top"
                             alt="ibancar_asnef">
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="card p-2">
                        <img 
                             height="50"
                             src="@/assets/img/logo-mrw.webp"
                             class="card-img-top"
                             alt="ibancar_mrw">
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="card p-2">
                        <img 
                             height="50"
                             src="@/assets/img/logo-ganvam.webp"
                             class="card-img-top"
                             alt="ibancar_ganvam">
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="card p-2">
                        <img 
                             height="50"
                             src="@/assets/img/BDO_logo-198_47.webp"
                             class="card-img-top"
                             alt="ibancar_bdo">
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="card p-2">
                        <img 
                             height="50"
                             src="@/assets/img/oracle-netsuite-logo-198_47.webp"
                             class="card-img-top"
                             alt="ibancar_oracle">
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="card p-2">
                        <img 
                             height="50"
                             src="@/assets/img/logo-kineox.webp"
                             class="card-img-top"
                             alt="kineox">
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="card p-2">
                        <img 
                             height="50"
                             src="@/assets/img/logo-psd2.webp"
                             class="card-img-top"
                             alt="psd2">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useMeta } from 'vue-meta'
import store from "@/store";
import { reactive } from 'vue';
import axios from 'axios';

export default {
    name: "Reclamaciones",
    setup() {

        useMeta({
            title: 'Reclamaciones',
            meta: [
                { name: 'robots', content: store.state.dominio == 'ibancar.com' ? 'index' : 'noindex' },
                { name: 'description', content: 'Préstamo con aval de coche sin cambio de titularidad. Consigue crédito hasta 6.000€ en 24h. Evita rentings y amortiza desde el primer día. Consigue dinero rápido.' },
                { name: 'keywords', content: 'Préstamo aval coche, Dinero urgente online, Crédito con coche, Préstamos rápidos y créditos rápidos de dinero online. Empeña tu coche. Refinanciación de coches.' }
            ]
        })

        const formData = reactive({
            fullName: "",
            email: "",
            phone: "",
            message: "",
            motive: ""
        })

        const isFormSend = reactive({
            correct: false,
            loading: null
        })

        const handleSubmit = () => {
            isFormSend.loading = true;
            axios.post(process.env.VUE_APP_AXIOS_BASE_URL + 'legalWebMail', {
                data: formData
            }).then(
                (resp) => {
                    if (resp) {
                        formData.fullName = '';
                        formData.email = '';
                        formData.phone = '';
                        formData.motive = '';
                        formData.message = '';
                        isFormSend.loading = false;
                        isFormSend.correct = true;
                    }
                }
            ).catch((e) => {
                console.error('Error: ' + e);
                formData.fullName = '';
                formData.email = '';
                formData.phone = '';
                formData.motive = '';
                formData.message = '';
                isFormSend.loading = false;
                isFormSend.correct = false;
            })
        }

        return {
            formData,
            handleSubmit,
            isFormSend
        }
    },
    methods: {
        sendForm() {
            console.log("ola")
        }
    }
};
</script>

<style scoped lang="scss">
@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_mixins.scss";

.blue-text {
    color: #004680;
    font-family: Montserrat;
}

h1.blue-text {
    font-family: Montserrat-Bold;
    font-size: 25px;
}

h2.blue-text {
    font-weight: 600;
    font-size: 17px;
}

p.blue-text {
    font-size: 12px;
    text-align: justify;
}

.container-fluid {
    position: relative;
    padding-bottom: 1rem;
}

.fade-orange {
    background: linear-gradient(0deg, rgba(255, 165, 0, 0.39) 0.5%, rgba(255, 200, 100, 0.47) 26%, rgba(255, 255, 255, 0.00) 68.5%);
    height: 3rem;
    position: absolute;
    top: 1;
    left: 0;
    right: 0;
    bottom: 0;
}

.blue-rectangle {
    height: 1.5rem;
    background-color: #B3DCFF;
    ;
}

.form-container {
    border-radius: 12px;
    background: rgba(255, 255, 255, 0.00);
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.25);
    padding: 2rem;
}

.form-information-container p {
    color: #737373;
    font-size: 12px;
    text-align: left;
    font-weight: 600;
}

.form-title {
    font-size: 1rem !important;
    margin: 0;
}

.form-subtitle {
    font-size: 0.9rem;
}

.form-container {
    gap: 1.5rem;

    form {

        label {
            width: 100%;
            display: flex;
            flex-direction: column;
            color: #004680;
            margin-bottom: 1rem;
            font-weight: 600;

            input {
                margin: 0;
                border: none;
                border-radius: 4px;
                background: #FFF;
                box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.20) inset;
                padding: 2px;
            }

            textarea {
                margin: 0;
                border: none;
                border-radius: 4px;
                background: #FFF;
                box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.20) inset;
            }

            select {
                padding: 10px;
                margin: 0;
                border: none;
                border-radius: 4px;
                background: #FFF;
                box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.20) inset;

            }
        }

        button {
            border-radius: 30px;
            background: #FFA500;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            color: white;
            padding: 0.5rem;
            font-size: 20px;
            border: none;
            font-weight: 600;
            width: 100%;
            margin-top: 1rem;
            max-width: 20rem;
        }

        .submit-container {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.form-separator {

    label {
        margin-bottom: 2rem !important;
    }
}

#desktop {
    margin-bottom: 5rem;

    .card {
        z-index: -1;
        width: 19rem;
        height: 16rem;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
}

#socios {
    .card {
        z-index: -1;
        box-shadow: none;
        border: none;
    }
}

.document-image {
    img {
        width: 3rem;
    }
}

.form-send-container {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
        font-size: 1rem;
    }
}

@include media-breakpoint-between(xs, sm) {
    #carousel {
        #mobile {
            .card {
                border: none;
                margin-left: 12.5%;
                margin-right: 12.5%;
                height: auto;
                width: 75%;
            }
        }
    }

    #desktop {
        display: none !important;
    }
}

@include media-breakpoint-down(sm) {
    #carousel {
        margin-bottom: 3rem;
    }
}

@include media-breakpoint-between(md, lg) {
    .title-h1 {
        font-size: 1.5rem;
        font-weight: bold;
    }

    #desktop {
        display: none !important;
    }

    .padding {
        padding: 0;
    }

    #clientes {
        padding-left: 7rem;
        padding-right: 7rem;
    }

    #prensa {
        img {
            filter: grayscale(100);
        }

        img:hover {
            filter: grayscale(0);
        }
    }

    #socios {
        img {
            filter: grayscale(0);
        }

        img:hover {
            filter: grayscale(0);
        }
    }

    #carousel {
        margin-bottom: 5rem;

        #mobile {
            .card {
                border: none;
                margin-left: 25%;
                margin-right: 25%;
                height: auto;
                width: 50%;

                img {
                    filter: grayscale(0);
                }
            }
        }
    }

    #desktop {
        display: none !important;
    }
}

@include media-breakpoint-between(xl, xxl) {
    #mobile {
        display: none !important;
    }

    .padding {
        padding: 2.5rem;
    }

    #clientes {
        padding-left: 7rem;
        padding-right: 7rem;
    }

    #prensa {
        img {
            filter: grayscale(0);
        }

        img:hover {
            filter: grayscale(0);
        }
    }

    #socios {
        img {
            filter: grayscale(0);
            ;
        }

        img:hover {
            filter: grayscale(0);
        }
    }
}

@include media-breakpoint-up(lg) {
    .container-fluid {
        padding: 0 8rem;
    }

    h1.blue-text {
        font-family: Montserrat-Bold;
        font-size: 35px;
    }

    h2.blue-text {
        font-weight: 600;
        font-size: 20px;
    }

    p.blue-text {
        font-size: 15px;
        text-align: justify;
    }

    .form-separator {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
        margin-bottom: 1rem;

        label {
            margin-bottom: 1rem !important;
        }
    }

    #mensaje {
        height: 10rem;
    }

    .banner-desc {
        padding-bottom: 1rem;
    }

}

.card  {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        width: 130px;
        height: 40px;
    }
}
</style>